import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { getBreadCrumbData } from './config'

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  constructor() {}

  getMenuData(): Observable<any[]> {
    return of(getBreadCrumbData)
  }
}
