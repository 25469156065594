import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as act from './actions'
import { reportService } from 'src/app/services/report'
import * as moment from 'moment'
import { CurrencyPipe } from '@angular/common'
import { MiscFunctions } from 'src/app/misc'

@Injectable()
export class ReportEffects {
  reportPlayerActivity: [] = []
  reportLimitActivity: [] = []
  reportFeeActivity: [] = []
  reportPaymentActivity: [] = []
  reportScoringFee: [] = []
  reportProgramFee: [] = []
  reportCWAEnableDisable: [] = []
  reportCwaDetail: [] = []
  reportCwaAdjustment: [] = []
  reportProgManamgementFees: [] = []

  constructor(
    private actions: Actions,
    private reportService: reportService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    private currencyPipe: CurrencyPipe,
    public misc: MiscFunctions,
  ) {}

  @Effect()
  getReportPlayerActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACTIVITY),
    map((action: act.ReportPlayerActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPlayerActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
            v.datecreated = moment(v.datecreated).format('YYYY-MM-DD hh:mm:ss A')
            v.datemodified = moment(v.datemodified).format('YYYY-MM-DD hh:mm:ss A')
            v.asset_time = moment(v.asset_time).format('YYYY-MM-DD hh:mm:ss A')
            ;(v.amount = v.debit + v.credit),
              (v.amountCurrency = this.currencyPipe.transform(v.amount, 'USD', 'symbol', '1.2-2'))
            return v
          })
          this.reportPlayerActivity = []
          this.reportPlayerActivity['ReportPlayerActivity'] = res
          return new act.ReportPlayerActivitySuccess(this.reportPlayerActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportLimitActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIMIT_ACTIVITY),
    map((action: act.ReportLimitActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLimitActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
            v.datecreated = moment(v.datecreated).format('YYYY-MM-DD hh:mm:ss A')
            v.datemodified = moment(v.datemodified).format('YYYY-MM-DD hh:mm:ss A')
            v.totalLimitCurrency = this.misc.transformCurrency(v.totalLimit)
            return v
          })
          this.reportLimitActivity = []
          this.reportLimitActivity['ReportLimitActivity'] = res
          return new act.ReportLimitActivitySuccess(this.reportLimitActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportFeeActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FEE_ACTIVITY),
    map((action: act.ReportFeeActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFeeActivity(payload).pipe(
        map(res => {
          res.data = res.data
            .filter(x => x.FeeType !== 'Scoring fee assessed to casino')
            .map(v => {
              v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
              v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
              v.BaseAmountCurrency = this.currencyPipe.transform(
                v.BaseAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.FeeAmountCurrency = this.currencyPipe.transform(
                v.FeeAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })
          this.reportFeeActivity = []
          this.reportFeeActivity['ReportFeeActivity'] = res
          return new act.ReportFeeActivitySuccess(this.reportFeeActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportPaymentActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PAYMENT_ACTIVITY),
    map((action: act.ReportPaymentActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPaymentActivity(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
            v.datecreated = moment(v.datecreated).format('YYYY-MM-DD hh:mm:ss A')
            v.datemodified = moment(v.datemodified).format('YYYY-MM-DD hh:mm:ss A')
            v.debitCurrency = this.currencyPipe.transform(v.debit, 'USD', 'symbol', '1.2-2')
            v.creditCurrency = this.currencyPipe.transform(v.credit, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportPaymentActivity = []
          this.reportPaymentActivity['ReportPaymentActivity'] = res
          return new act.ReportPaymentActivitySuccess(this.reportPaymentActivity)
        }),
      )
    }),
  )
  @Effect()
  getReportScoringFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_SCORING_FEES),
    map((action: act.ReportScoringFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportScoringFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
            v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
            v.BaseAmountCurrency = this.currencyPipe.transform(
              v.BaseAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.FeeAmountCurrency = this.currencyPipe.transform(v.FeeAmount, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportScoringFee = []
          this.reportScoringFee['ReportScoringFee'] = res
          return new act.ReportScoringFeeSuccess(this.reportScoringFee)
        }),
      )
    }),
  )
  @Effect()
  getReportProgramFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PROGRAM_FEES),
    map((action: act.ReportProgramFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportProgramFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.accountingdate = moment(v.accountingdate).format('MM/DD/YYYY')
            v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
            v.BaseAmountCurrency = this.currencyPipe.transform(
              v.BaseAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.FeeAmountCurrency = this.currencyPipe.transform(v.FeeAmount, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportProgramFee'] = res
          return new act.ReportProgramFeeSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportCWAEnableDisable: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_ENABLE_DISABLE),
    map((action: act.ReportCWAEnableDisable) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCWAEnableDisable(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
            return v
          })
          this.reportCWAEnableDisable = []
          this.reportCWAEnableDisable['ReportCWAEnableDisable'] = res
          return new act.ReportCWAEnableDisableSuccess(this.reportCWAEnableDisable)
        }),
      )
    }),
  )
  @Effect()
  getReportLimitAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIMIT_ADJUSTMENT),
    map((action: act.ReportLimitAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLimitAdjustment(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = v.DateModified
              ? moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportLimitAdjustment'] = res
          return new act.ReportLimitAdjustmentSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportFee: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FEE),
    map((action: act.ReportFee) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFee(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate
              ? moment(v.CalendarDate).format('YYYY-MM-DD hh:mm A')
              : ''
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD') : ''
            v.FeeCurrency = this.currencyPipe.transform(v.Fee, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['ReportFee'] = res
          return new act.ReportFeeSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getMTSummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_MT_SUMMARY),
    map((action: act.ReportMTSummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportMTSummary(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.ModifiedDate = v.ModifiedDate
              ? moment(v.ModifiedDate).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['MTSummary'] = res
          return new act.ReportMTSummarySuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportUnderwriting: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_UNDERWRITING),
    map((action: act.ReportUnderwriting) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportUnderwriting(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CREDIT_FACTOR_CREATE_DATE = v.CREDIT_FACTOR_CREATE_DATE
              ? moment(v.CREDIT_FACTOR_CREATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.CREDIT_FACTOR_UPDATE_DATE = v.CREDIT_FACTOR_UPDATE_DATE
              ? moment(v.CREDIT_FACTOR_UPDATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['Underwriting'] = res
          return new act.ReportUnderwritingSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportCwaDetail: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_DETAIL),
    map((action: act.ReportCwaDetail) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCwaDetail(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            v.CalendarDateTime = v.CalendarDateTime
              ? moment(v.CalendarDateTime).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportCwaDetail = []
          this.reportCwaDetail['ReportCwaDetail'] = res
          return new act.ReportCwaDetailSuccess(this.reportCwaDetail)
        }),
      )
    }),
  )
  @Effect()
  getReportCwaAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CWA_ADJUSTMENT),
    map((action: act.ReportCwaAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCwaAdjustment(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            v.CreateDate = v.CreateDate ? moment(v.CreateDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })
          this.reportCwaAdjustment = []
          this.reportCwaAdjustment['ReportCwaAdjustment'] = res
          return new act.ReportCwaAdjustmentSuccess(this.reportCwaAdjustment)
        }),
      )
    }),
  )
  @Effect()
  getPatronCwaDetails: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PATRON_CWA_DETAILS),
    map((action: act.ReportCwaAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPatronCwaDetails(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate ? moment(v.CalendarDate).format('YYYY-MM-DD') : ''

            return v
          })
          this.reportCwaAdjustment = []
          this.reportCwaAdjustment['ReportPatronCwaDetails'] = res
          return new act.ReportCwaAdjustmentSuccess(this.reportCwaAdjustment)
        }),
      )
    }),
  )
  @Effect()
  getReportProgManagementFees: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PROGRAM_MANAGEMENT_FEES),
    map((action: act.ProgramManagementFees) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getProgManagementFeesReport(payload).pipe(
        map(res => {
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ProgramManagementFees'] = res
          return new act.ProgramManagementFeesSuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getActivitySummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_ACTIVITY_SUMMARY),
    map((action: act.ActivitySummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getActivitySummary(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CalendarDate = v.CalendarDate
              ? moment(v.CalendarDate).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.GamingDate = v.GamingDate ? moment(v.GamingDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })

          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ActivitySummary'] = res
          return new act.ActivitySummarySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getReportHistory: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_HISTORY),
    map((action: act.ReportHistory) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportHistory(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.DateModified = v.DateModified
              ? moment(v.DateModified).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['ReportHistory'] = res
          return new act.ReportHistorySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getPatronLiability: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PATRON_LIABILITY),
    map((action: act.PatronLiability) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPatronLiability(payload).pipe(
        map(res => {
          this.reportProgManamgementFees = []
          this.reportProgManamgementFees['PatronLiability'] = res
          return new act.PatronLiabilitySuccess(this.reportProgManamgementFees)
        }),
      )
    }),
  )
  @Effect()
  getReportGGR: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_GGR),
    map((action: act.ReportGGR) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportGGR(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.CREDIT_FACTOR_CREATE_DATE = v.CREDIT_FACTOR_CREATE_DATE
              ? moment(v.CREDIT_FACTOR_CREATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            v.CREDIT_FACTOR_UPDATE_DATE = v.CREDIT_FACTOR_UPDATE_DATE
              ? moment(v.CREDIT_FACTOR_UPDATE_DATE).format('YYYY-MM-DD hh:mm:ss A')
              : ''
            return v
          })
          this.reportProgramFee = []
          this.reportProgramFee['GGR'] = res
          return new act.ReportGGRSuccess(this.reportProgramFee)
        }),
      )
    }),
  )
  @Effect()
  getReportPlayerActivityDetail: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACTIVITY_DETAIL),
    map((action: act.ReportPlayerActivityDetail) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPlayerActivityDetail(payload).pipe(
        map(res => {
          res.data = res.data
          return new act.ReportPlayerActivityDetailSuccess(res)
        }),
      )
    }),
  )
  @Effect()
  getReportFailedTransaction: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FAILED_TRANSACTION),
    map((action: act.ReportFailedTransaction) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFailedTransaction(payload).pipe(
        map(res => {
          res.data = res.data
          return new act.ReportFailedTransactionSuccess(res)
        }),
      )
    }),
  )
}
