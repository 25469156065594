import { settingsService } from './services/settings/index'
import { Permission } from 'src/app/services/permission/index'
import { jwtAuthService } from 'src/app/services/jwt'
import { ConfigService } from './services/config.service'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import qs from 'qs'
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map, mergeMap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import store from 'store'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'
import IdleTimer from 'src/app/services/IdleTimer'
import { MiscFunctions } from 'src/app/misc/index'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'
import { Keepalive } from '@ng-idle/keepalive'

import english from './locales/en-US'
import french from './locales/fr-FR'
import russian from './locales/ru-RU'
import chinese from './locales/zh-CN'
import { NzModalFooterDirective } from 'ng-zorro-antd'

const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
}

@Component({
  selector: 'app-root',
  template: `
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  _locale: String
  _theme: String
  configLoaded: boolean = false
  timer: any
  sesssionTime: any
  settings = {}

  idleState = 'Not started.'
  timedOut = false
  lastPing?: Date = null
  title = 'angular-idle-timeout'

  constructor(
    private settingService: settingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private store: Store<any>,
    translate: TranslateService,
    private http: HttpClient,
    public config: ConfigService,
    public jwtAuthService: jwtAuthService,
    private misc: MiscFunctions,
    private permission: Permission,
    private idle: Idle,
    private keepalive: Keepalive,
  ) {
    Object.keys(locales).forEach(locale => {
      translate.setTranslation(locale, locales[locale])
    })
    translate.setDefaultLang('en-US')

    // localization && theme listener
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      if (this._locale !== state.locale) {
        translate.use(state.locale)
      }
      if (this._theme !== state.theme) {
        this.setTheme(state.theme)
      }
      this._locale = state.locale
      this._theme = state.theme
    })
  }

  reset() {
    this.idle.watch()
    //xthis.idleState = 'Started.';
    this.timedOut = false
  }

  ngOnInit() {
    this.settingService.getAuthSettings().subscribe(async (d: any) => {
      d.Data.map(d => {
        this.settings[d.Code] = d.Value
      })
      if (this.settings['SESSION_TIMEOUT'] > 0) {
        // this.permission.idleTimeOut(this.settings['SESSION_TIMEOUT'])

        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(this.settings['SESSION_TIMEOUT'] * 60)
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(5)
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

        this.idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer this.idle.'
          this.reset()
        })

        this.idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!'
          this.timedOut = true
          this.router.navigate(['/'])
        })

        this.idle.onIdleStart.subscribe(() => {
          this.idleState = "You've gone idle!"
        })

        this.idle.onTimeoutWarning.subscribe(countdown => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!'
        })

        // sets the ping interval to 15 seconds
        this.keepalive.interval(15)

        this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()))
        this.jwtAuthService.currentAccount().subscribe(userLoggedIn => {
          if (userLoggedIn) {
            this.idle.watch()
            this.timedOut = false
          } else {
            this.idle.stop()
          }
        })
      }
    })

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event =>
        this.titleService.setTitle(this.config.getConfig().title + ' | ' + event['title']),
      )

    // listen url query params and set them to ngrx store
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const queryString = event.url.match(/\?(.*)/)
        if (queryString) {
          const queryParams = qs.parse(queryString[1])
          const keys = Object.keys(queryParams)
          if (keys.length) {
            keys.forEach(key => {
              let value
              switch (queryParams[key]) {
                case 'false':
                  value = false
                  break
                case 'true':
                  value = true
                  break
                default:
                  value = queryParams[key]
                  break
              }
              this.store.dispatch(
                new SettingsActions.SetStateAction({
                  [key]: value,
                }),
              )
            })
          }
        }
      })

    // detecting & set mobile/tablet/desktop viewports
    const setViewPort = (isMobileView: any = false, isTabletView: any = false) => {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          isMobileView,
        }),
      )
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          isTabletView,
        }),
      )
    }
    const detectViewPort = (load = false) => {
      const _isMobileView = window.innerWidth < 768
      const _isTabletView = window.innerWidth < 992
      const _isDesktopView = !_isMobileView && !_isTabletView
      const isMobileView = store.get('app.settings.isMobileView')
      const isTabletView = store.get('app.settings.isTabletView')
      const isDesktopView = !isMobileView && !isTabletView
      if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
        setViewPort(false, false)
      }
      if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
        setViewPort(false, true)
        this.store.dispatch(
          new SettingsActions.SetStateAction({
            isMenuCollapsed: true,
          }),
        )
      }
      if (_isMobileView && (_isMobileView !== isMobileView || load)) {
        setViewPort(true, false)
      }
    }
    detectViewPort(true)
    window.addEventListener('resize', () => {
      detectViewPort()
    })

    // set primary color on app load
    const primaryColor = () => {
      const color = store.get('app.settings.primaryColor')
      if (color) {
        const addStyles = () => {
          const styleElement = document.querySelector('#primaryColor')
          if (styleElement) {
            styleElement.remove()
          }
          const body = document.querySelector('body')
          const styleEl = document.createElement('style')
          const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
          styleEl.setAttribute('id', 'primaryColor')
          styleEl.appendChild(css)
          body.appendChild(styleEl)
        }
        addStyles()
        this.store.dispatch(
          new SettingsActions.SetStateAction({
            primaryColor: color,
          }),
        )
      }
    }
    primaryColor()
  }

  setTheme = theme => {
    document.querySelector('html').setAttribute('data-kit-theme', theme)
    if (theme === 'default') {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          menuColor: 'light',
        }),
      )
    }
    if (theme === 'dark') {
      this.store.dispatch(
        new SettingsActions.SetStateAction({
          menuColor: 'dark',
        }),
      )
    }
  }
}
