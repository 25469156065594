export const getMenuData: any[] = [
  {
    category: true,
    title: 'Dashboard',
  },
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: 'fe fe-home',
    url: '/dashboard',
    // roles: ['admin'], // set user roles with access to this route
  },
  {
    title: 'Players',
    key: 'players',
    icon: 'fe fe-users',
    url: '/player-management/players',
  },
  {
    title: 'Reports',
    key: 'reports',
    icon: 'fe fe-bar-chart-2',
    url: '/reports',
  },
  {
    title: 'Employees',
    key: 'employees',
    icon: 'fe fe-users',
    url: '/employees',
  },
  {
    title: 'Settlement',
    key: 'settlement',
    icon: 'fe fe-file',
    url: '/settlement',
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: 'fe fe-settings',
    url: '/settings',
    position: 'bottom',
  },

  // {
  //   title: 'Properties Management',
  //   key: 'propertiesManagement',
  //   icon: 'fe fe-users',
  //   url: '/properties-management',
  // },
  // {
  //   title: 'Notifications',
  //   key: 'notifications',
  //   icon: 'fe fe-bell',
  //   url: '/notifications',
  // },
]
