import { ConfigService } from './../../../../services/config.service'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'cui-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  constructor(public config: ConfigService) {}
  env: any

  ngOnInit() {
    this.env = this.config.getConfig().env
  }
}
