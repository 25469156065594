<div class="breadcrumbs">
  <div class="path">
    <a href routerLink="/dashboard">Dashboard</a>
    <ng-container *ngFor="let item of breadcrumbs; let last = last;">
      <span *ngIf="!last">
        <span> / </span>
        <a href routerLink="{{item.url}}">
          <span>{{ item.title }}</span>
        </a>
      </span>
      <span *ngIf="last && item.title">
        <span> / </span>
        <span class="current fw-500">{{ item.title }}</span>
      </span>
    </ng-container>
  </div>
</div>