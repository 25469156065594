import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store'
import { environment } from 'src/environments/environment'
import * as fromRouter from '@ngrx/router-store'
import * as fromSettings from './settings/reducers'
import * as fromUser from './user/reducers'
import * as fromUserManagement from './userManagement/reducers'
import * as fromPlayer from './player/reducers'
import * as fromReport from './report/reducers'
export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  settings: fromSettings.reducer,
  user: fromUser.reducer,
  userManagement: fromUserManagement.reducer,
  player: fromPlayer.reducer,
  report: fromReport.reducer,
}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action)
    // console.groupCollapsed(action.type)
    // console.log('prev state', state)
    // console.log('action', action)
    // console.log('next state', result)
    // console.groupEnd()
    return result
  }
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : []

export const getSettingsState = createFeatureSelector<any>('settings')
export const getSettings = createSelector(getSettingsState, fromSettings.getSettings)
export const getUserState = createFeatureSelector<any>('user')
export const getUser = createSelector(getUserState, fromUser.getUser)
export const loadCurrentAccount = createSelector(getUserState, fromUser.loadCurrentAccount)
export const loginSettings = createSelector(getUserState, fromUser.loginSettings)

export const getPlayerState = createFeatureSelector<any>('player')
export const getPlayerList = createSelector(getPlayerState, fromPlayer.getPlayerList)
export const getPlayerDetails = createSelector(getPlayerState, fromPlayer.getPlayerDetails)
export const getPlayerFeeInfo = createSelector(getPlayerState, fromPlayer.getPlayerFeeInfo)
export const getPlayerMarkerInfo = createSelector(getPlayerState, fromPlayer.getPlayerMarkerInfo)
export const getPlayerActList = createSelector(getPlayerState, fromPlayer.getPlayerActList)
export const getLimitActList = createSelector(getPlayerState, fromPlayer.getLimitActList)
export const getFeeActList = createSelector(getPlayerState, fromPlayer.getFeeActList)
export const getStatement = createSelector(getPlayerState, fromPlayer.getStatement)
export const getPaymentActList = createSelector(getPlayerState, fromPlayer.getPaymentActList)
export const getTransaction = createSelector(getPlayerState, fromPlayer.getTransaction)
export const makePaymentResult = createSelector(getPlayerState, fromPlayer.makePaymentResult)
export const cashOutResult = createSelector(getPlayerState, fromPlayer.cashOutResult)
export const internalAdjustmentResult = createSelector(
  getPlayerState,
  fromPlayer.internalAdjustmentResult,
)
export const makeAdjustmentResult = createSelector(getPlayerState, fromPlayer.makeAdjustmentResult)
export const generateAgreement = createSelector(getPlayerState, fromPlayer.generateAgreement)
export const previewAgreement = createSelector(getPlayerState, fromPlayer.previewAgreement)
export const generateStatement = createSelector(getPlayerState, fromPlayer.generateStatement)
export const getCreditFactor = createSelector(getPlayerState, fromPlayer.getCreditFactor)

export const getUserManagementState = createFeatureSelector<any>('userManagement')
export const getUserList = createSelector(getUserManagementState, fromUserManagement.getUserList)
export const getEventLogList = createSelector(
  getUserManagementState,
  fromUserManagement.getEventLogList,
)
export const getRoleList = createSelector(getUserManagementState, fromUserManagement.getRoleList)
export const getGroupList = createSelector(getUserManagementState, fromUserManagement.getGroupList)
export const getModuleList = createSelector(
  getUserManagementState,
  fromUserManagement.getModuleList,
)
export const opsResetPwdResult = createSelector(
  getUserManagementState,
  fromUserManagement.opsResetPwdResult,
)
export const addUserResult = createSelector(
  getUserManagementState,
  fromUserManagement.addUserResult,
)

export const getReportState = createFeatureSelector<any>('report')
export const getReportPlayerActivity = createSelector(
  getReportState,
  fromReport.getReportPlayerActivity,
)
export const getReportLimitActivity = createSelector(
  getReportState,
  fromReport.getReportLimitActivity,
)
export const getReportPaymentActivity = createSelector(
  getReportState,
  fromReport.getReportPaymentActivity,
)
export const getReportFeeActivity = createSelector(getReportState, fromReport.getReportFeeActivity)
export const getReportScoringFee = createSelector(getReportState, fromReport.getReportScoringFee)
export const getReportProgramFee = createSelector(getReportState, fromReport.getReportProgramFee)
export const getReportCWAEnableDisable = createSelector(
  getReportState,
  fromReport.getReportCWAEnableDisable,
)
export const getReportLimitAdjustment = createSelector(
  getReportState,
  fromReport.getReportLimitAdjustment,
)
export const getReportFee = createSelector(getReportState, fromReport.getReportFee)
export const getReportMTSummary = createSelector(getReportState, fromReport.getReportMTSummary)
export const getUnderwritingReport = createSelector(
  getReportState,
  fromReport.getUnderwritingReport,
)
export const getReportCwaDetail = createSelector(getReportState, fromReport.getReportCwaDetail)
export const getReportCwaAdjustment = createSelector(
  getReportState,
  fromReport.getReportCwaAdjustment,
)
export const getReportPatronCwaDetails = createSelector(
  getReportState,
  fromReport.getReportPatronCwaDetails,
)
export const getProgManagementFeesReport = createSelector(
  getReportState,
  fromReport.getProgManagementFeesReport,
)
export const getReportHistory = createSelector(getReportState, fromReport.getReportHistory)
export const getActivitySummary = createSelector(getReportState, fromReport.getActivitySummary)
export const getPatronLiability = createSelector(getReportState, fromReport.getPatronLiability)
export const getReportFailedTransaction = createSelector(
  getReportState,
  fromReport.getReportFailedTransaction,
)
export const getReportGGR = createSelector(getReportState, fromReport.getReportGGR)
export const getReportPlayerActivityDetail = createSelector(
  getReportState,
  fromReport.getReportPlayerActivityDetail,
)
