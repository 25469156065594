import { Action } from '@ngrx/store'

export const LOGIN = '[Auth] Login'
export const LOGIN_SUCCESSFUL = '[Auth] Login Succesful'
export const LOGIN_UNSUCCESSFUL = '[Auth] Login Unsuccesful'
export const REGISTER = '[Auth] Register'
export const REGISTER_SUCCESSFUL = '[Auth] Register Succesful'
export const REGISTER_UNSUCCESSFUL = '[Auth] Register Unsuccesful'
export const LOAD_CURRENT_ACCOUNT = '[Auth] Load Current Account'
export const LOAD_CURRENT_ACCOUNT_SUCCESSFUL = '[Auth] Load Current Account Succesful'
export const LOAD_CURRENT_ACCOUNT_UNSUCCESSFUL = '[Auth] Load Current Account Unsuccesful'
export const LOGOUT = '[Auth] Logout'
export const LOGOUT_SUCCESSFUL = '[Auth] Logout Succesful'
export const LOGOUT_UNSUCCESSFUL = '[Auth] Logout Unsuccesful'
export const FLUSH_USER = '[User] Flush User'
export const EMPTY_ACTION = '[User] Empty Action'
export const FORGOT_PASSWORD = '[User] Forgot Password'
export const FORGOT_PASSWORD_SUCCESSFUL = '[User] Forgot Password Succesful'
export const FORGOT_PASSWORD_UNSUCCESSFUL = '[User] Forgot Password Unsuccesful'
export const SET_PASSWORD = '[User] Set Password'
export const SET_PASSWORD_SUCCESSFUL = '[User] Set Password Succesful'
export const SET_PASSWORD_UNSUCCESSFUL = '[User] Set Password Unsuccesful'
export const LOGIN_SETTINGS = '[Auth] Login Settings'
export const LOGIN_SETTINGS_SUCCESSFUL = '[Auth] Login Settings Successful'
export const LOGIN_SETTINGS_UNSUCCESSFUL = '[Auth] Login Settings Unsuccessful'
export const SET_IPADDRESS = '[User] Set IPAddress'
export const SET_IPADDRESS_SUCCESSFUL = '[User] Set IPAddress Succesful'
export const SET_IPADDRESS_UNSUCCESSFUL = '[User] Set IPAddress Unsuccesful'
export const VALIDATE_IPADDRESS = '[User] Validate IPAddress'
export const VALIDATE_IPADDRESS_SUCCESSFUL = '[User] Validate IPAddress Succesful'
export const VALIDATE_IPADDRESS_UNSUCCESSFUL = '[User] Validate IPAddress Unsuccesful'

export class Login implements Action {
  readonly type = LOGIN
  constructor(public payload: any) {}
}

export class LoginSuccessful implements Action {
  readonly type = LOGIN_SUCCESSFUL
  constructor(public payload: any) {}
}

export class LoginUnsuccessful implements Action {
  readonly type = LOGIN_UNSUCCESSFUL
  constructor() {}
}

export class Register implements Action {
  readonly type = REGISTER
  constructor(public payload: any) {}
}

export class RegisterSuccessful implements Action {
  readonly type = REGISTER_SUCCESSFUL
  constructor(public payload: any) {}
}

export class RegisterUnsuccessful implements Action {
  readonly type = REGISTER_UNSUCCESSFUL
  constructor() {}
}

export class LoadCurrentAccount implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT
  constructor() {}
}

export class LoadCurrentAccountSuccessful implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT_SUCCESSFUL
  constructor(public payload: any) {}
}

export class LoadCurrentAccountUnsuccessful implements Action {
  readonly type = LOAD_CURRENT_ACCOUNT_UNSUCCESSFUL
  constructor() {}
}

export class Logout implements Action {
  readonly type = LOGOUT
  constructor() {}
}

export class FlushUser implements Action {
  readonly type = FLUSH_USER
  constructor() {}
}

export class EmptyAction implements Action {
  readonly type = EMPTY_ACTION
  constructor() {}
}

export class ForgotPassword implements Action {
  readonly type = FORGOT_PASSWORD
  constructor(public payload: any) {}
}

export class ForgotPasswordSuccessful implements Action {
  readonly type = FORGOT_PASSWORD_SUCCESSFUL
  constructor(public payload: any) {}
}

export class ForgotPasswordUnsuccessful implements Action {
  readonly type = FORGOT_PASSWORD_UNSUCCESSFUL
  constructor() {}
}

export class SetPassword implements Action {
  readonly type = SET_PASSWORD
  constructor(public payload: any) {}
}

export class SetPasswordSuccessful implements Action {
  readonly type = SET_PASSWORD_SUCCESSFUL
  constructor(public payload: any) {}
}

export class SetPasswordUnsuccessful implements Action {
  readonly type = SET_PASSWORD_UNSUCCESSFUL
  constructor() {}
}

export class LogoutSuccessful implements Action {
  readonly type = LOGOUT_SUCCESSFUL
  constructor(public payload: any) {}
}

export class LogoutUnsuccessful implements Action {
  readonly type = LOGOUT_UNSUCCESSFUL
  constructor() {}
}

export class LoginSettings implements Action {
  readonly type = LOGIN_SETTINGS
  constructor() {}
}

export class LoginSettingsSuccessful implements Action {
  readonly type = LOGIN_SETTINGS_SUCCESSFUL
  constructor(public payload: any) {}
}

export class LoginSettingsUnsuccessful implements Action {
  readonly type = LOGIN_SETTINGS_UNSUCCESSFUL
  constructor() {}
}

export class SetIPAddress implements Action {
  readonly type = SET_IPADDRESS
  constructor(public payload: any) {}
}

export class SetIPAddressSuccessful implements Action {
  readonly type = SET_IPADDRESS_SUCCESSFUL
  constructor(public payload: any) {}
}

export class SetIPAddressUnsuccessful implements Action {
  readonly type = SET_IPADDRESS_UNSUCCESSFUL
  constructor() {}
}

export class ValidateIPAddress implements Action {
  readonly type = VALIDATE_IPADDRESS
  constructor(public payload: any) {}
}

export class ValidateIPAddressSuccessful implements Action {
  readonly type = VALIDATE_IPADDRESS_SUCCESSFUL
  constructor(public payload: any) {}
}

export class ValidateIPAddressUnsuccessful implements Action {
  readonly type = VALIDATE_IPADDRESS_UNSUCCESSFUL
  constructor() {}
}

export type Actions =
  | Login
  | LoginSuccessful
  | LoginUnsuccessful
  | Register
  | RegisterSuccessful
  | RegisterUnsuccessful
  | LoadCurrentAccount
  | LoadCurrentAccountSuccessful
  | LoadCurrentAccountUnsuccessful
  | Logout
  | FlushUser
  | EmptyAction
  | ForgotPassword
  | ForgotPasswordSuccessful
  | ForgotPasswordUnsuccessful
  | SetPassword
  | SetPasswordSuccessful
  | SetPasswordUnsuccessful
  | LogoutSuccessful
  | LogoutUnsuccessful
  | LoginSettings
  | LoginSettingsSuccessful
  | LoginSettingsUnsuccessful
  | SetIPAddress
  | SetIPAddressSuccessful
  | SetIPAddressUnsuccessful
  | ValidateIPAddress
  | ValidateIPAddressSuccessful
  | ValidateIPAddressUnsuccessful
