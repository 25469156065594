<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" >
    <nz-badge>
      <nz-avatar nzIcon="user" [nzSize]="'large'" class="avatar"></nz-avatar>
    </nz-badge>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
        <div>
          <strong>
            {{ 'topBar.profileMenu.role' | translate }}:
          </strong>
          {{role || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a routerLink="/edit-profile">
          <i class="fe fe-user mr-2"></i>
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-item>
        <a routerLink="/change-password">
          <i class="fe fe-settings mr-2"></i>
          Change {{settings['PASSWORD_LABEL']}}
        </a>
      </li>
      <li nz-menu-divider></li>

      <li nz-menu-item (click)="logout()">

        <a href="javascript: void(0);" >
          <i class="fe fe-log-out mr-2"></i>
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>