import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import session from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Injectable()
export class userMenuService {
  constructor(private http: CustomHttpService, private router: Router) {}

  updateProfile(userData): Observable<any> {
    return this.http.post(`Identity/UpdateUserInfoById`, {
      USER_ID: userData.UserId,
      FIRSTNAME: userData.FirstName,
      LASTNAME: userData.LastName,
      MOBILE: userData.Mobile.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(
        /\s+/g,
        '',
      ),
      ROLE_ID: userData.UserRoleId,
      TITLE: userData.Title,
      BADGENUMBER: userData.BadgeNumber,
      DOB: userData.Dob ? moment(userData.Dob).format('YYYY-MM-DD') : '',
    })
  }

  updatePassword(passData): Observable<any> {
    let data = {
      NewPassword: passData.newPassword,
      OldPassword: passData.oldPassword,
    }
    return this.http.put(`Identity/ChangePassword`, JSON.stringify(data))
  }
}
