import { jwtAuthService } from './../jwt/index'
import { MenuService } from './../menu/index'
import { Injectable, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import store from 'store'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import IdleTimer from 'src/app/services/IdleTimer'
import { LocalService } from '../local.service'
@Injectable()
export class Permission implements OnInit {
  public userProfilePicture: any

  public MenuAccess = {}
  public allAccess = {}
  menuData: any[]
  session: any
  settings = {
    USERNAME_LABEL: '',
    PASSWORD_LABEL: '',
    PASSWORD_MIN_CHAR: '',
    PASSWORD_MAX_CHAR: '',
    PASSWORD_AL_NUMBER: '',
    PASSWORD_AL_CAP_LETTER: '',
    PASSWORD_AL_SML_LETTER: '',
    PASSWORD_AL_SPL_CHAR: '',
    SESSION_TIMEOUT: '',
    MAX_ATTEMP: '',
    USER_LOCK_TIMEOUT: '',
    MULTI_SESSION: '',
    ALLOW_SELF_WHITELIST: '',
  }
  sesssionTime: any
  timer: any
  constructor(
    private router: Router,
    private menuService: MenuService,
    private Store: Store<any>,
    private jwtAuthService: jwtAuthService,
    private localService: LocalService,
  ) {
    this.getMenuAccess()
    this.loginSettings()
  }

  ngOnInit(): void {
    this.loginSettings()
    setTimeout(() => {
      if (this.settings) {
        this.idleTimeOut(this.settings['SESSION_TIMEOUT'])
      }
    }, 1000)
  }

  getMenuAccess() {
    if (this.localService.getJsonValue('userInfo'))
      this.session = JSON.parse(this.localService.getJsonValue('userInfo'))
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.MenuAccess = {}
    this.allAccess = {}
    return this.menuData.reduce((menuAccess, access) => {
      if (this.session && this.session.SubModulePermission) {
        menuAccess[access] = this.session.SubModulePermission.filter(submodule => {
          return submodule.ModuleName == access.key
        }).map(main_module => {
          this.allAccess[main_module.ModuleName] = main_module.ModulePermission
            ? main_module.ModulePermission.split('~')
            : main_module.ModulePermission
          if (main_module.SubModules) {
            main_module.SubModules.map(sub_module => {
              this.allAccess[sub_module.ModuleName] = sub_module.ModulePermission
                ? sub_module.ModulePermission.split('~')
                : sub_module.ModulePermission
              if (sub_module.SubModules) {
                sub_module.SubModules.map(child_module => {
                  this.allAccess[child_module.ModuleName] = child_module.ModulePermission
                    ? child_module.ModulePermission.split('~')
                    : child_module.ModulePermission
                })
              }
            })
          }
          return main_module
        })
        return menuAccess
      }
    }, this.MenuAccess)
  }

  hasAccessTo(module, access) {
    this.getMenuAccess()
    return (
      typeof this.allAccess[module] != 'undefined' && this.allAccess[module].indexOf(access) >= 0
    )
  }
  hasMenuAccessTo(menu) {
    this.getMenuAccess()
    return typeof this.MenuAccess[menu] != 'undefined' && this.MenuAccess[menu].length > 1
  }
  loginSettings() {
    this.Store.dispatch(new UserActions.LoginSettings())
    this.Store.pipe(select(Reducers.loginSettings)).subscribe(state => {
      let data = state.LoginSettings
      if (data) {
        data.map(d => {
          this.settings[d.Code] = d.Value
          this.localService.setJsonValue('settings', JSON.stringify(this.settings))
        })
      }
    })
    return this.settings
      ? this.settings
      : this.localService.getJsonValue('settings')
      ? JSON.parse(this.localService.getJsonValue('settings'))
      : {}
  }
  idleTimeOut(time) {
    if (this.localService.getJsonValue('SessionId') != undefined) {
      this.timer = new IdleTimer({
        timeout: time, //expired after 5 mins
        onTimeout: () => {
          this.jwtAuthService.logout()
          this.router.navigate(['auth/login'])
        },
      })
    }
  }
}
