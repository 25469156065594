import { Action } from '@ngrx/store'

export const RESET = 'Reset'

export const USER_LIST = 'Get UserList'
export const USER_LIST_SUCCESSFUL = 'Get UserList Succesful'
export const USER_LIST_UNSUCCESSFUL = 'Get UserList Unsuccesful'

export const ROLE_LIST = 'Get RoleList'
export const ROLE_LIST_SUCCESSFUL = 'Get RoleList Succesful'
export const ROLE_LIST_UNSUCCESSFUL = 'Get RoleList Unsuccesful'

export const GROUP_LIST = 'Get GroupList'
export const GROUP_LIST_SUCCESSFUL = 'Get GroupList Succesful'
export const GROUP_LIST_UNSUCCESSFUL = 'Get GroupList Unsuccesful'

export const ADD_USER = 'Add User'
export const ADD_USER_SUCCESSFUL = 'Add User Succesful'
export const ADD_USER_UNSUCCESSFUL = 'Add User Unsuccesful'

export const UPDATE_USER = 'Update User'
export const UPDATE_USER_SUCCESSFUL = 'Update User Succesful'
export const UPDATE_USER_UNSUCCESSFUL = 'Update User Unsuccesful'

export const DELETE_USER = 'Delete User'
export const DELETE_USER_SUCCESSFUL = 'Delete User Succesful'
export const DELETE_USER_UNSUCCESSFUL = 'Delete User Unsuccesful'

export const UPDATE_GROUP = 'Update Group'
export const UPDATE_GROUP_SUCCESSFUL = 'Update Group Succesful'
export const UPDATE_GROUP_UNSUCCESSFUL = 'Update Group Unsuccesful'

export const DELETE_GROUP = 'Delete Group'
export const DELETE_GROUP_SUCCESSFUL = 'Delete Group Succesful'
export const DELETE_GROUP_UNSUCCESSFUL = 'Delete Group Unsuccesful'

export const MODULE_LIST = 'Get ModuleList'
export const MODULE_LIST_SUCCESSFUL = 'Get ModuleList Succesful'
export const MODULE_LIST_UNSUCCESSFUL = 'Get ModuleList Unsuccesful'

export const ADD_ROLE = 'Add Role'
export const ADD_ROLE_SUCCESSFUL = 'Add Role Succesful'
export const ADD_ROLE_UNSUCCESSFUL = 'Add Role Unsuccesful'

export const UPDATE_ROLE = 'Update Role'
export const UPDATE_ROLE_SUCCESSFUL = 'Update Role Succesful'
export const UPDATE_ROLE_UNSUCCESSFUL = 'Update Role Unsuccesful'

export const DELETE_ROLE = 'Delete Role'
export const DELETE_ROLE_SUCCESSFUL = 'Delete Role Succesful'
export const DELETE_ROLE_UNSUCCESSFUL = 'Delete Role Unsuccesful'

export const ADD_PERMISSION = 'Add Permission'
export const ADD_PERMISSION_SUCCESSFUL = 'Add Permission Succesful'
export const ADD_PERMISSION_UNSUCCESSFUL = 'Add Permission Unsuccesful'

export const OPS_RESET_PWD = 'Reset Password'
export const OPS_RESET_PWD_SUCCESSFUL = 'Reset Password Succesful'
export const OPS_RESET_PWD_UNSUCCESSFUL = 'Reset Password Unsuccesful'

export const EVENT_LOG_LIST = 'Get EventLogList'
export const EVENT_LOG_LIST_SUCCESSFUL = 'Get EventLogList Succesful'
export const EVENT_LOG_LIST_UNSUCCESSFUL = 'Get EventLogList Unsuccesful'

/* Event Log list */
export class EventLogList implements Action {
  readonly type = EVENT_LOG_LIST
  constructor(public data: any) {}
}
export class EventLogListSuccess implements Action {
  readonly type = EVENT_LOG_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class EventLogUnSuccess implements Action {
  readonly type = EVENT_LOG_LIST_UNSUCCESSFUL
  constructor() {}
}

/* user list */
export class UserList implements Action {
  readonly type = USER_LIST
  constructor() {}
}
export class UserListSuccess implements Action {
  readonly type = USER_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class UserListUnSuccess implements Action {
  readonly type = USER_LIST_UNSUCCESSFUL
  constructor() {}
}

/* add user */
export class AddUser implements Action {
  readonly type = ADD_USER
  constructor(public data: any) {}
}
export class AddUserSuccess implements Action {
  readonly type = ADD_USER_SUCCESSFUL
  constructor(public data: any) {}
}
export class AddUserUnSuccess implements Action {
  readonly type = ADD_USER_UNSUCCESSFUL
  constructor() {}
}

/* update user */
export class UpdateUser implements Action {
  readonly type = UPDATE_USER
  constructor(public data: any) {}
}
export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESSFUL
  constructor(public data: any) {}
}
export class UpdateUserUnSuccess implements Action {
  readonly type = UPDATE_USER_UNSUCCESSFUL
  constructor() {}
}

/* delete user */
export class DeleteUser implements Action {
  readonly type = DELETE_USER
  constructor(public data: any) {}
}
export class DeleteUserSuccess implements Action {
  readonly type = DELETE_USER_SUCCESSFUL
  constructor(public data: any) {}
}
export class DeleteUserUnSuccess implements Action {
  readonly type = DELETE_USER_UNSUCCESSFUL
  constructor() {}
}

/* role list */
export class RoleList implements Action {
  readonly type = ROLE_LIST
  constructor() {}
}
export class RoleListSuccess implements Action {
  readonly type = ROLE_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class RoleListUnSuccess implements Action {
  readonly type = ROLE_LIST_UNSUCCESSFUL
  constructor() {}
}

/* group list */
export class GroupList implements Action {
  readonly type = GROUP_LIST
  constructor() {}
}
export class GroupListSuccess implements Action {
  readonly type = GROUP_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class GroupListUnSuccess implements Action {
  readonly type = GROUP_LIST_UNSUCCESSFUL
  constructor() {}
}

/* update group */
export class UpdateGroup implements Action {
  readonly type = UPDATE_GROUP
  constructor(public data: any) {}
}
export class UpdateGroupSuccess implements Action {
  readonly type = UPDATE_GROUP_SUCCESSFUL
  constructor(public data: any) {}
}
export class UpdateGroupUnSuccess implements Action {
  readonly type = UPDATE_GROUP_UNSUCCESSFUL
  constructor() {}
}

/* delete group */
export class DeleteGroup implements Action {
  readonly type = DELETE_GROUP
  constructor(public data: any) {}
}
export class DeleteGroupSuccess implements Action {
  readonly type = DELETE_GROUP_SUCCESSFUL
  constructor(public data: any) {}
}
export class DeleteGroupUnSuccess implements Action {
  readonly type = DELETE_GROUP_UNSUCCESSFUL
  constructor() {}
}

/* module list */
export class ModuleList implements Action {
  readonly type = MODULE_LIST
  constructor() {}
}
export class ModuleListSuccess implements Action {
  readonly type = MODULE_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class ModuleListUnSuccess implements Action {
  readonly type = MODULE_LIST_UNSUCCESSFUL
  constructor() {}
}

/* add role */
export class AddRole implements Action {
  readonly type = ADD_ROLE
  constructor(public data: any) {}
}
export class AddRoleSuccess implements Action {
  readonly type = ADD_ROLE_SUCCESSFUL
  constructor(public data: any) {}
}
export class AddRoleUnSuccess implements Action {
  readonly type = ADD_ROLE_UNSUCCESSFUL
  constructor() {}
}

/* update role */
export class UpdateRole implements Action {
  readonly type = UPDATE_ROLE
  constructor(public data: any) {}
}
export class UpdateRoleSuccess implements Action {
  readonly type = UPDATE_ROLE_SUCCESSFUL
  constructor(public data: any) {}
}
export class UpdateRoleUnSuccess implements Action {
  readonly type = UPDATE_ROLE_UNSUCCESSFUL
  constructor() {}
}

/* delete role */
export class DeleteRole implements Action {
  readonly type = DELETE_ROLE
  constructor(public data: any) {}
}
export class DeleteRoleSuccess implements Action {
  readonly type = DELETE_ROLE_SUCCESSFUL
  constructor(public data: any) {}
}
export class DeleteRoleUnSuccess implements Action {
  readonly type = DELETE_ROLE_UNSUCCESSFUL
  constructor() {}
}

/* add permission */
export class AddPermission implements Action {
  readonly type = ADD_PERMISSION
  constructor(public data: any) {}
}
export class AddPermissionSuccess implements Action {
  readonly type = ADD_PERMISSION_SUCCESSFUL
  constructor(public data: any) {}
}
export class AddPermissionUnSuccess implements Action {
  readonly type = ADD_PERMISSION_UNSUCCESSFUL
  constructor() {}
}

/* operator reset pwd */
export class OpsResetPwd implements Action {
  readonly type = OPS_RESET_PWD
  constructor(public data: any) {}
}
export class OpsResetPwdSuccess implements Action {
  readonly type = OPS_RESET_PWD_SUCCESSFUL
  constructor(public data: any) {}
}
export class OpsResetPwdUnSuccess implements Action {
  readonly type = OPS_RESET_PWD_UNSUCCESSFUL
  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET
  constructor() {}
}

export type Actions =
  | Reset
  | EventLogList
  | EventLogListSuccess
  | EventLogUnSuccess
  | UserList
  | UserListSuccess
  | UserListUnSuccess
  | RoleList
  | RoleListSuccess
  | RoleListUnSuccess
  | GroupList
  | GroupListSuccess
  | GroupListUnSuccess
  | AddUser
  | AddUserSuccess
  | AddUserUnSuccess
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserUnSuccess
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserUnSuccess
  | UpdateGroup
  | UpdateGroupSuccess
  | UpdateGroupUnSuccess
  | DeleteGroup
  | DeleteGroupSuccess
  | DeleteGroupUnSuccess
  | ModuleList
  | ModuleListSuccess
  | ModuleListUnSuccess
  | AddRole
  | AddRoleSuccess
  | AddRoleUnSuccess
  | UpdateRole
  | UpdateRoleSuccess
  | UpdateRoleUnSuccess
  | DeleteRole
  | DeleteRoleSuccess
  | DeleteRoleUnSuccess
  | AddPermission
  | AddPermissionSuccess
  | AddPermissionUnSuccess
  | OpsResetPwd
  | OpsResetPwdSuccess
  | OpsResetPwdUnSuccess
