import { Action } from '@ngrx/store'

export const UPDATE_PROFILE = 'Update Profile'
export const UPDATE_PROFILE_SUCCESSFUL = 'Update Profile Succesful'
export const UPDATE_PROFILE_UNSUCCESSFUL = 'Update Profile Unsuccesful'

export const UPDATE_PASSWORD = 'Update Password'
export const UPDATE_PASSWORD_SUCCESSFUL = 'Update Password Succesful'
export const UPDATE_PASSWORD_UNSUCCESSFUL = 'Update Password Unsuccesful'

/* update profile */
export class UpdateProfile implements Action {
  readonly type = UPDATE_PROFILE
  constructor(public data: any) {}
}
export class UpdateProfileSuccess implements Action {
  readonly type = UPDATE_PROFILE_SUCCESSFUL
  constructor(public data: any) {}
}
export class UpdateProfileUnSuccess implements Action {
  readonly type = UPDATE_PROFILE_UNSUCCESSFUL
  constructor() {}
}

/* update password */
export class UpdatePassword implements Action {
  readonly type = UPDATE_PASSWORD
  constructor(public data: any) {}
}
export class UpdatePasswordSuccess implements Action {
  readonly type = UPDATE_PASSWORD_SUCCESSFUL
  constructor(public data: any) {}
}
export class UpdatePasswordUnSuccess implements Action {
  readonly type = UPDATE_PASSWORD_UNSUCCESSFUL
  constructor() {}
}

export type Actions =
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileUnSuccess
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordUnSuccess
