import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { DatePipe, CurrencyPipe } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class MiscFunctions {
  ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  methodDesc = {
    Cash: 'PAYMENT_CASH',
    Check: 'PAYMENT_CHECK',
    Chips: 'PAYMENT_CHIP',
    positive: 'Adjustment Positive',
    negative: 'Adjustment Negative',
  }
  constructor(private store: Store<any>, public currencyPipe: CurrencyPipe) {}

  cleanstring(str: string) {
    if (str) {
      while (str.indexOf(' ') >= 0) str = str.replace(' ', '')
      while (str.indexOf('(') >= 0) str = str.replace('(', '')
      while (str.indexOf(')') >= 0) str = str.replace(')', '')
      while (str.indexOf('-') >= 0) str = str.replace('-', '')
      while (str.indexOf('%') >= 0) str = str.replace('%', '')
      while (str.indexOf('-') >= 0) str = str.replace('-', '')
      return str.trim()
    } else return ''
  }

  reformstring(e, val?) {
    let v = e ? e.target.value : val
    let str: string = this.cleanstring(v)
    let newstr = ''
    for (let i = 0; i < str.length; i++) {
      switch (i) {
        case 0:
          newstr += '('
          break
        case 3:
          newstr += ') '
          break
        case 6:
          newstr += '-'
          break
      }
      newstr += str[i]
    }
    if (e) e.target.value = newstr
    else return newstr
  }

  dateFormat(e, val?) {
    let v = e ? e.target.value : val
    let str: string = this.cleanstring(v)
    let newstr = ''

    for (let i = 0; i < str.length; i++) {
      switch (i) {
        case 4:
          newstr += '-'
          break
        case 6:
          newstr += '-'
          break
      }
      if (i < 8) newstr += str[i]
    }
    if (e) e.target.value = newstr
    else return newstr
  }

  numbersOnly(e) {
    let allowed = ['Backspace', 'Enter', 'Delete', 'Tab', '0']
    return allowed.indexOf(e.key) >= 0 || !!Number(e.key)
  }

  lettersOnly(e) {
    if (
      (e.keyCode >= 15 && e.keyCode <= 64) ||
      e.keyCode >= 123 ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      e.preventDefault()
    }
  }

  convertTens(num) {
    if (num.length == 2 && num[0] == 0) return this.ones[num[1]]
    if (num < 10) return this.ones[num]
    else if (num >= 10 && num < 20) return this.teens[num - 10]
    else return `${this.tens[Math.floor(num / 10)]} ${this.ones[num % 10]}`
  }

  convertHundreds(num) {
    if (num > 99) {
      return `${this.ones[Math.floor(num / 100)]} hundred ${this.convertTens(num % 100)}`
    }
    return this.convertTens(num)
  }

  convertThousands(num) {
    if (num >= 1000) {
      return `${this.convertHundreds(Math.floor(num / 1000))} thousand ${this.convertHundreds(
        num % 1000,
      )}`
    }
    return this.convertHundreds(num)
  }

  convertMillions(num) {
    if (num >= 1000000) {
      return `${this.convertMillions(Math.floor(num / 1000000))} million ${this.convertThousands(
        num % 1000000,
      )}`
    }
    return this.convertThousands(num)
  }

  convert(num) {
    if (num === 0) return 'zero'
    return this.convertMillions(num)
  }

  async commafy(number) {
    var str = number.toString().split('.')
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ')
    }
    return str.join('.')
  }

  numberFormat(num) {
    num.replace(/,/gi, '')

    return num.split(/(?=(?:\d{3})+$)/).join(',')
  }
  renameMethod(key) {
    return this.methodDesc[key] ? this.methodDesc[key] : key
  }
  transformCurrency(value, nosymbol?): string {
    value = parseFloat(value)
    value = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2')
    if (nosymbol) value = value.replace('$', '')
    if (value !== undefined && value !== null) {
      return value.toString().replace(/,/g, '')
    } else {
      return ''
    }
  }
}
