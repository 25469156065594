import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class playerService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getPlayerList(): Observable<any> {
    return this.http.get(`Dashboard/Patron/10000/1`)
  }

  getPlayerDetails(params): Observable<any> {
    return this.http.get(`Dashboard/Patron/${params.data}`)
  }

  getPlayerFeeInfo(params): Observable<any> {
    return this.http.get(`Dashboard/FeeActivity/${params.data}`)
  }

  getPlayerMarkerInfo(params): Observable<any> {
    return this.http.get(`Dashboard/MarkerLimit/${params.data}`)
  }

  makePayment(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      method: params.data.method,
    }
    return this.http.post(
      `Dashboard/cwaDeposit/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }

  updateStatus(params): Observable<any> {
    return this.http.patch(`Identity/UpdatePlayerStatus`, params)
  }

  makeAdjustment(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      method: params.data.method,
    }
    return this.http.post(
      `Dashboard/cwaWithdraw/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }

  getPlayerAct(params): Observable<any> {
    return this.http.get(`Dashboard/PlayerActivity/${params.data}/10000/1`)
  }

  getLimitAct(params): Observable<any> {
    return this.http.get(`Dashboard/MarkerLimit/${params.data}`)
  }

  getFeeAct(params): Observable<any> {
    return this.http.get(`Dashboard/FeeActivity/${params.data}/10000/1`)
  }

  getPaymentAct(params): Observable<any> {
    if (params.data.api == 'All') params.data.api = 'PaymentActivity'
    else params.data.api = 'Payment/' + params.data.api
    return this.http.get(`Dashboard/${params.data.api}/${params.data.card}/10000/1`)
  }

  getTransaction(params): Observable<any> {
    return this.http.get(`Dashboard/AdjustmentActivity/${params.data}/10000/1`)
  }

  getStatementList(params): Observable<any> {
    return this.http.get(`Dashboard/Statement/${params.data}`)
  }

  playerAgreement(params): Observable<any> {
    const data = {
      sign_dt: params.data.date,
    }
    return this.http.post(
      `Dashboard/SignAgreement/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  generateAgreement(params): Observable<any> {
    return this.http.post(`Dashboard/GenerateAgreementV2/${params.data.playerCardNumber}`, {})
  }
  generateStatement(params): Observable<any> {
    return this.http.getv2(`Dashboard/Statement/Download/${params.data}`, {
      responseType: 'blob',
    })
  }
  previewAgreement(patronId): Observable<any> {
    // return this.http.postBlob(`Dashboard/PreviewAgreement/${params.data}`, JSON.stringify(params))
    return this.http.post(`Dashboard/PreviewAgreementV2/${patronId.data}`, {})
  }

  getCreditFactor(playerCardNumber): Observable<any> {
    return this.http.get(`Dashboard/CreditFactor/${playerCardNumber}`)
  }

  cashOut(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      pin: params.data.pin,
    }
    return this.http.post(`Dashboard/cashout/${params.data.playerCardNumber}`, JSON.stringify(data))
  }
  internalAdjustment(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      description: params.data.description,
      adjustmenttype: params.data.method,
    }
    return this.http.post(
      `Dashboard/adjustment/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  getPatronStatus(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PatronStatusCount${param}`)
  }
  getTotalFee(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/TransactionCountTotalFee${param}`)
  }
  getPaymentInfo(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PaymentInformation${param}`)
  }
  getPlayerNotes(id): Observable<any> {
    return this.http.get(`Identity/GetAllPatronNote/${id}`)
  }
  addPlayerNotes(params): Observable<any> {
    return this.http.post(`Identity/AddPatronNotes`, params)
  }
  editPlayerNotes(params): Observable<any> {
    return this.http.patch(`Identity/EditPatronNotes`, params)
  }
  deletePlayerNotes(id): Observable<any> {
    return this.http.delete(`Identity/DeletePatronNotes/${id}`)
  }
}
