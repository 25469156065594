<div class="d-inline-block mr-4">
  <nz-input-group class="extInput" [nzPrefix]="prefixIconSearch">
    <input nz-input (focus)="setVisible()" placeholder="{{ 'topBar.typeToSearch' | translate }}" style="width: 200px" />
  </nz-input-group>
  <ng-template #prefixIconSearch>
    <i nz-icon nzType="search" class="extInputIcon" style="color: rgba(0,0,0,.25)"></i>
  </ng-template>
  <div class="livesearch" [ngClass]="{'livesearchVisible': showSearch}">
    <button class="close" type="button" (click)="setHidden()">
      <i class="icmn-cross"></i>
    </button>
    <div class="container-fluid">
      <div class="wrapper">
        <div class="inner-wrap">
        <div class="font-size-18 font-weight-bold text-dark">Search</div>
        <nz-input-group nzPrefixIcon="search">
          <input nz-input #liveSearchInput [(ngModel)]="searchText" class="searchInput"
          placeholder="{{ 'topBar.typeToSearch' | translate }}"  (ngModelChange)="filterPlayer()" placeholder="Search" nzSize="large" />
        </nz-input-group>
    
        <ul class="options">
          <!-- <li class="option optionCheckbox">
            <label nz-checkbox>Search within page</label>
          </li> -->
          <!-- <li class="option">Press enter to search</li> -->
        </ul>
        <div *ngIf="!searchText" class="results">
          <div class="resultsTitle">
            <span>No Results Found</span>
          </div>
        </div>
        <div *ngIf="searchText" class="results">
          <div class="resultsTitle">
            <span>Search Results:</span>
          </div>
          <div class="row">
            <div class="col-lg-12" *ngFor="let player of filteredPlayerList" [routerLink]="['/player-management/player-details', player.patronId]" (click)="setHidden()">
              <div class="resultContent" >
                <!-- <div class="resultThumb" style="background-image: url(assets/images/content/photos/1.jpeg)">
                  {{player.firstName[0]}}{{player.lastName[0]}}
                </div> -->
                <div class="result">
                  <div class="number">{{player.playerCardNumber}}</div>
                  <div class="firstName">{{player.firstName}}</div>
                  <div class="lastName"> {{player.lastName}}</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>