import * as act from './actions'

export const initialState: object = {}

export function reducer(state = initialState, action: act.Actions): object {
  switch (action.type) {
    case act.RESET:
      return initialState //Always return the initial state
    case act.PLAYER_LIST:
    case act.PLAYER_DETAILS:
    case act.PLAYER_FEE_INFO:
    case act.PLAYER_MARKER_INFO:
    case act.MAKE_PAYMENT:
    case act.MAKE_ADJUSTMENT:
    case act.PLAYER_ACT_LIST:
    case act.LIMIT_ACT_LIST:
    case act.FEE_ACT_LIST:
    case act.PAYMENT_ACT_LIST:
    case act.PLAYER_AGREEMENT:
    case act.GENERATE_AGREEMENT:
    case act.PREVIEW_AGREEMENT:
    case act.GENERATE_STATEMENT:
    case act.STATEMENT:
    case act.CREDIT_FACTOR:
    case act.CASHOUT:
    case act.INTERNAL_ADJUSTMENT:
    case act.TRANSACTION_LIST:
      return {
        ...state,
        loading: true,
      }
    case act.PLAYER_LIST_SUCCESSFUL:
    case act.PLAYER_DETAILS_SUCCESSFUL:
    case act.PLAYER_FEE_INFO_SUCCESSFUL:
    case act.PLAYER_MARKER_INFO_SUCCESSFUL:
    case act.MAKE_PAYMENT_SUCCESSFUL:
    case act.MAKE_ADJUSTMENT_SUCCESSFUL:
    case act.PLAYER_ACT_LIST_SUCCESSFUL:
    case act.LIMIT_ACT_LIST_SUCCESSFUL:
    case act.FEE_ACT_LIST_SUCCESSFUL:
    case act.PAYMENT_ACT_LIST_SUCCESSFUL:
    case act.PLAYER_AGREEMENT_SUCCESSFUL:
    case act.GENERATE_AGREEMENT_SUCCESSFUL:
    case act.PREVIEW_AGREEMENT_SUCCESSFUL:
    case act.STATEMENT_SUCCESSFUL:
    case act.GENERATE_STATEMENT_SUCCESSFUL:
    case act.CREDIT_FACTOR_SUCCESSFUL:
    case act.CASHOUT_SUCCESSFUL:
    case act.INTERNAL_ADJUSTMENT_SUCCESSFUL:
    case act.TRANSACTION_LIST_SUCCESSFUL:
      return {
        ...state,
        ...action.data,
        loading: false,
        authorized: true,
      }
    case act.PLAYER_LIST_UNSUCCESSFUL:
    case act.PLAYER_DETAILS_UNSUCCESSFUL:
    case act.PLAYER_FEE_INFO_UNSUCCESSFUL:
    case act.MAKE_PAYMENT_UNSUCCESSFUL:
    case act.MAKE_ADJUSTMENT_UNSUCCESSFUL:
    case act.PLAYER_ACT_LIST_UNSUCCESSFUL:
    case act.LIMIT_ACT_LIST_UNSUCCESSFUL:
    case act.FEE_ACT_LIST_UNSUCCESSFUL:
    case act.PAYMENT_ACT_LIST_UNSUCCESSFUL:
    case act.PLAYER_AGREEMENT_UNSUCCESSFUL:
    case act.GENERATE_AGREEMENT_UNSUCCESSFUL:
    case act.PREVIEW_AGREEMENT_UNSUCCESSFUL:
    case act.STATEMENT_UNSUCCESSFUL:
    case act.GENERATE_STATEMENT_UNSUCCESSFUL:
    case act.PLAYER_MARKER_INFO_UNSUCCESSFUL:
    case act.CREDIT_FACTOR_UNSUCCESSFUL:
    case act.CASHOUT_UNSUCCESSFUL:
    case act.INTERNAL_ADJUSTMENT_UNSUCCESSFUL:
    case act.TRANSACTION_LIST_UNSUCCESSFUL:
      return {
        ...state,
        loading: false,
        authorized: false,
      }
    default:
      return state
  }
}

export const getPlayerList = (state: any) => state
export const getPlayerDetails = (state: any) => state
export const getPlayerFeeInfo = (state: any) => state
export const getPlayerMarkerInfo = (state: any) => (state.authorized ? state.playerMarkerInfo : [])

export const getPlayerActList = (state: any) => state
export const getLimitActList = (state: any) => state
export const getFeeActList = (state: any) => state
export const getPaymentActList = (state: any) => state
export const getTransaction = (state: any) => state
export const getStatement = (state: any) => state

export const makePaymentResult = (state: any) => state
export const makeAdjustmentResult = (state: any) => state
export const playerAgreement = (state: any) => state
export const generateAgreement = (state: any) => (state.authorized ? state : [])
export const previewAgreement = (state: any) => state
export const generateStatement = (state: any) => state
export const getCreditFactor = (state: any) => (state.authorized ? state.creditFactor : [])
export const cashOutResult = (state: any) => state
export const internalAdjustmentResult = (state: any) => state
