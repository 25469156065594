<nz-layout>
  <nz-content>
    <div class="authContainer">
      <div class="containerInner">
        <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>