import { Action } from '@ngrx/store'

export const REPORT_PLAYER_ACTIVITY = 'Get ReportPlayerActivity'
export const REPORT_PLAYER_ACTIVITY_SUCCESSFUL = 'Get ReportPlayerActivity Successful'
export const REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL = 'Get ReportPlayerActivity Unsuccessful'

export const REPORT_LIMIT_ACTIVITY = 'Get ReportLimitActivity'
export const REPORT_LIMIT_ACTIVITY_SUCCESSFUL = 'Get ReportLimitActivity Successful'
export const REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL = 'Get ReportLimitActivity Unsuccessful'

export const REPORT_FEE_ACTIVITY = 'Get ReportFeeActivity'
export const REPORT_FEE_ACTIVITY_SUCCESSFUL = 'Get ReportFeeActivity Successful'
export const REPORT_FEE_ACTIVITY_UNSUCCESSFUL = 'Get ReportFeeActivity Unsuccessful'

export const REPORT_PAYMENT_ACTIVITY = 'Get ReportPaymentActivity'
export const REPORT_PAYMENT_ACTIVITY_SUCCESSFUL = 'Get ReportPaymentActivity Successful'
export const REPORT_PAYMENT_ACTIVITY_UNSUCCESSFUL = 'Get ReportPaymentActivity Unsuccessful'

export const REPORT_SCORING_FEES = 'Get ReportScoringFees'
export const REPORT_SCORING_FEES_SUCCESSFUL = 'Get ReportScoringFees Successful'
export const REPORT_SCORING_FEES_UNSUCCESSFUL = 'Get ReportScoringFees Unsuccessful'

export const REPORT_PROGRAM_FEES = 'Get ReportProgramFees'
export const REPORT_PROGRAM_FEES_SUCCESSFUL = 'Get ReportProgramFees Successful'
export const REPORT_PROGRAM_FEES_UNSUCCESSFUL = 'Get ReportProgramFees Unsuccessful'

export const REPORT_CWA_ENABLE_DISABLE = 'Get ReportCWAEnableDisable'
export const REPORT_CWA_ENABLE_DISABLE_SUCCESSFUL = 'Get ReportCWAEnableDisable Successful'
export const REPORT_CWA_ENABLE_DISABLE_UNSUCCESSFUL = 'Get ReportCWAEnableDisable Unsuccessful'

export const REPORT_LIMIT_ADJUSTMENT = 'Get ReportLimitAdjustment'
export const REPORT_LIMIT_ADJUSTMENT_SUCCESSFUL = 'Get ReportLimitAdjustment Successful'
export const REPORT_LIMIT_ADJUSTMENT_UNSUCCESSFUL = 'Get ReportLimitAdjustment Unsuccessful'

export const REPORT_FEE = 'Get ReportFee'
export const REPORT_FEE_SUCCESSFUL = 'Get ReportFee Successful'
export const REPORT_FEE_UNSUCCESSFUL = 'Get ReportFee Unsuccessful'

export const REPORT_MT_SUMMARY = 'Get MTSummary'
export const REPORT_MT_SUMMARY_SUCCESSFUL = 'Get MTSummary Successful'
export const REPORT_MT_SUMMARY_UNSUCCESSFUL = 'Get MTSummary Unsuccessful'

export const REPORT_UNDERWRITING = 'Get ReportUnderwritng'
export const REPORT_UNDERWRITING_SUCCESSFUL = 'Get ReportUnderwritng Successful'
export const REPORT_UNDERWRITING_UNSUCCESSFUL = 'Get ReportUnderwritng Unsuccessful'
export const REPORT_CWA_DETAIL = 'Get ReportCwaDetail'
export const REPORT_CWA_DETAIL_SUCCESSFUL = 'Get ReportCwaDetail Successful'
export const REPORT_CWA_DETAIL_UNSUCCESSFUL = 'Get ReportCwaDetail Unsuccessful'

export const REPORT_CWA_ADJUSTMENT = 'Get ReportCwaAdjustment'
export const REPORT_CWA_ADJUSTMENT_SUCCESSFUL = 'Get ReportCwaAdjustment Successful'
export const REPORT_CWA_ADJUSTMENT_UNSUCCESSFUL = 'Get ReportCwaAdjustment Unsuccessful'

export const REPORT_PATRON_CWA_DETAILS = 'Get PatronCwaDetails'
export const REPORT_PATRON_CWA_DETAILS_SUCCESSFUL = 'Get PatronCwaDetails Successful'
export const REPORT_PATRON_CWA_DETAILS_UNSUCCESSFUL = 'Get PatronCwaDetails Unsuccessful'

export const REPORT_PROGRAM_MANAGEMENT_FEES = 'Get ProgramManagementFees'
export const REPORT_PROGRAM_MANAGEMENT_FEES_SUCCESSFUL = 'Get ProgramManagementFees Successful'
export const REPORT_PROGRAM_MANAGEMENT_FEES_UNSUCCESSFUL = 'Get ProgramManagementFees Unsuccessful'

export const REPORT_ACTIVITY_SUMMARY = 'Get ActivitySummary'
export const REPORT_ACTIVITY_SUMMARY_SUCCESSFUL = 'Get ActivitySummary Successful'
export const REPORT_ACTIVITY_SUMMARY_UNSUCCESSFUL = 'Get ActivitySummary Unsuccessful'

export const REPORT_HISTORY = 'Get ReportHistory'
export const REPORT_HISTORY_SUCCESSFUL = 'Get ReportHistory Successful'
export const REPORT_HISTORY_UNSUCCESSFUL = 'Get ReportHistory Unsuccessful'

export const REPORT_PATRON_LIABILITY = 'Get PatronLiability'
export const REPORT_PATRON_LIABILITY_SUCCESSFUL = 'Get PatronLiability Successful'
export const REPORT_PATRON_LIABILITY_UNSUCCESSFUL = 'Get PatronLiability Unsuccessful'

export const REPORT_GGR = 'Get GGR'
export const REPORT_GGR_SUCCESSFUL = 'Get GGR Successful'
export const REPORT_GGR_UNSUCCESSFUL = 'Get GGR Unsuccessful'

export const REPORT_PLAYER_ACTIVITY_DETAIL = 'Get PlayerActivityDetail'
export const REPORT_PLAYER_ACTIVITY_DETAIL_SUCCESSFUL = 'Get PlayerActivityDetail Successful'
export const REPORT_PLAYER_ACTIVITY_DETAIL_UNSUCCESSFUL = 'Get PlayerActivityDetail Unsuccessful'

export const REPORT_FAILED_TRANSACTION = 'Get FailedTransaction'
export const REPORT_FAILED_TRANSACTION_SUCCESSFUL = 'Get FailedTransaction Successful'
export const REPORT_FAILED_TRANSACTION_UNSUCCESSFUL = 'Get FailedTransaction Unsuccessful'

/* Player Activity Detail */
export class ReportPlayerActivityDetail implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_DETAIL
  constructor(public payload: any) {}
}
export class ReportPlayerActivityDetailSuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_DETAIL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPlayerActivityDetailUnSuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_DETAIL_UNSUCCESSFUL
  constructor() {}
}

/* GGR */
export class ReportGGR implements Action {
  readonly type = REPORT_GGR
  constructor(public payload: any) {}
}
export class ReportGGRSuccess implements Action {
  readonly type = REPORT_GGR_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportGGRUnSuccess implements Action {
  readonly type = REPORT_GGR_UNSUCCESSFUL
  constructor() {}
}

/* report patron liability */
export class PatronLiability implements Action {
  readonly type = REPORT_PATRON_LIABILITY
  constructor(public payload: any) {}
}
export class PatronLiabilitySuccess implements Action {
  readonly type = REPORT_PATRON_LIABILITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class PatronLiabilityUnSuccess implements Action {
  readonly type = REPORT_PATRON_LIABILITY_UNSUCCESSFUL
  constructor() {}
}

/* report report history */
export class ReportHistory implements Action {
  readonly type = REPORT_HISTORY
  constructor(public payload: any) {}
}
export class ReportHistorySuccess implements Action {
  readonly type = REPORT_HISTORY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportHistoryUnSuccess implements Action {
  readonly type = REPORT_HISTORY_UNSUCCESSFUL
  constructor() {}
}

/* report activity summary */
export class ActivitySummary implements Action {
  readonly type = REPORT_ACTIVITY_SUMMARY
  constructor(public payload: any) {}
}
export class ActivitySummarySuccess implements Action {
  readonly type = REPORT_ACTIVITY_SUMMARY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ActivitySummaryUnSuccess implements Action {
  readonly type = REPORT_ACTIVITY_SUMMARY_UNSUCCESSFUL
  constructor() {}
}

/* report program management fees */
export class ProgramManagementFees implements Action {
  readonly type = REPORT_PROGRAM_MANAGEMENT_FEES
  constructor(public payload: any) {}
}
export class ProgramManagementFeesSuccess implements Action {
  readonly type = REPORT_PROGRAM_MANAGEMENT_FEES_SUCCESSFUL
  constructor(public data: any) {}
}
export class ProgramManagementFeesUnSuccess implements Action {
  readonly type = REPORT_PROGRAM_MANAGEMENT_FEES_UNSUCCESSFUL
  constructor() {}
}
/* report player activity */
export class ReportPlayerActivity implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY
  constructor(public payload: any) {}
}
export class ReportPlayerActivitySuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPlayerActivityUnSuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}

/* report limit activity */
export class ReportLimitActivity implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY
  constructor(public payload: any) {}
}
export class ReportLimitActivitySuccess implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportLimitActivityUnSuccess implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}

/* report player fee */
export class ReportFeeActivity implements Action {
  readonly type = REPORT_FEE_ACTIVITY
  constructor(public payload: any) {}
}
export class ReportFeeActivitySuccess implements Action {
  readonly type = REPORT_FEE_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportFeeActivityUnSuccess implements Action {
  readonly type = REPORT_FEE_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}

/* report player payment */
export class ReportPaymentActivity implements Action {
  readonly type = REPORT_PAYMENT_ACTIVITY
  constructor(public payload: any) {}
}
export class ReportPaymentActivitySuccess implements Action {
  readonly type = REPORT_PAYMENT_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPaymentActivityUnSuccess implements Action {
  readonly type = REPORT_PAYMENT_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}

/* report scoring fee */
export class ReportScoringFee implements Action {
  readonly type = REPORT_SCORING_FEES
  constructor(public payload: any) {}
}
export class ReportScoringFeeSuccess implements Action {
  readonly type = REPORT_SCORING_FEES_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportScoringFeeUnSuccess implements Action {
  readonly type = REPORT_SCORING_FEES_UNSUCCESSFUL
  constructor() {}
}

/* report program fee */
export class ReportProgramFee implements Action {
  readonly type = REPORT_PROGRAM_FEES
  constructor(public payload: any) {}
}
export class ReportProgramFeeSuccess implements Action {
  readonly type = REPORT_PROGRAM_FEES_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportProgramFeeUnSuccess implements Action {
  readonly type = REPORT_PROGRAM_FEES_UNSUCCESSFUL
  constructor() {}
}

/* report CWA Enable Disable */
export class ReportCWAEnableDisable implements Action {
  readonly type = REPORT_CWA_ENABLE_DISABLE
  constructor(public payload: any) {}
}
export class ReportCWAEnableDisableSuccess implements Action {
  readonly type = REPORT_CWA_ENABLE_DISABLE_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCWAEnableDisableUnSuccess implements Action {
  readonly type = REPORT_CWA_ENABLE_DISABLE_UNSUCCESSFUL
  /* limit adjustment */
}
export class ReportLimitAdjustment implements Action {
  readonly type = REPORT_LIMIT_ADJUSTMENT
  constructor(public payload: any) {}
}
export class ReportLimitAdjustmentSuccess implements Action {
  readonly type = REPORT_LIMIT_ADJUSTMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportLimitAdjustmentUnSuccess implements Action {
  readonly type = REPORT_LIMIT_ADJUSTMENT_UNSUCCESSFUL
  constructor() {}
}

export class ReportFee implements Action {
  readonly type = REPORT_FEE
  constructor(public payload: any) {}
}
export class ReportFeeSuccess implements Action {
  readonly type = REPORT_FEE_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportFeeUnSuccess implements Action {
  readonly type = REPORT_FEE_UNSUCCESSFUL
  constructor() {}
}

export class ReportMTSummary implements Action {
  readonly type = REPORT_MT_SUMMARY
  constructor(public payload: any) {}
}
export class ReportMTSummarySuccess implements Action {
  readonly type = REPORT_MT_SUMMARY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportMTSummaryUnSuccess implements Action {
  readonly type = REPORT_MT_SUMMARY_UNSUCCESSFUL
  constructor() {}
}

export class ReportUnderwriting implements Action {
  readonly type = REPORT_UNDERWRITING
  constructor(public payload: any) {}
}
export class ReportUnderwritingSuccess implements Action {
  readonly type = REPORT_UNDERWRITING_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportUnderwritingUnSuccess implements Action {
  readonly type = REPORT_UNDERWRITING_UNSUCCESSFUL
  constructor() {}
}
/* report CWA Detail */
export class ReportCwaDetail implements Action {
  readonly type = REPORT_CWA_DETAIL
  constructor(public payload: any) {}
}
export class ReportCwaDetailSuccess implements Action {
  readonly type = REPORT_CWA_DETAIL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCwaDetailUnSuccess implements Action {
  readonly type = REPORT_CWA_DETAIL_UNSUCCESSFUL
  constructor() {}
}
/* report CWA Adjustment */
export class ReportCwaAdjustment implements Action {
  readonly type = REPORT_CWA_ADJUSTMENT
  constructor(public payload: any) {}
}
export class ReportCwaAdjustmentSuccess implements Action {
  readonly type = REPORT_CWA_ADJUSTMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCwaAdjustmentUnSuccess implements Action {
  readonly type = REPORT_CWA_ADJUSTMENT_UNSUCCESSFUL
  constructor() {}
}

/* report Patron CWA Details */
export class ReportPatronCwaDetails implements Action {
  readonly type = REPORT_PATRON_CWA_DETAILS
  constructor(public payload: any) {}
}
export class ReportPatronCwaDetailsSuccess implements Action {
  readonly type = REPORT_PATRON_CWA_DETAILS_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPatronCwaDetailsUnSuccess implements Action {
  readonly type = REPORT_PATRON_CWA_DETAILS_UNSUCCESSFUL
  constructor() {}
}

/* report Failed Transaction Report */
export class ReportFailedTransaction implements Action {
  readonly type = REPORT_FAILED_TRANSACTION
  constructor(public payload: any) {}
}
export class ReportFailedTransactionSuccess implements Action {
  readonly type = REPORT_FAILED_TRANSACTION_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportFailedTransactionUnSuccess implements Action {
  readonly type = REPORT_FAILED_TRANSACTION_UNSUCCESSFUL
  constructor() {}
}

export type Actions =
  | ReportPlayerActivity
  | ReportPlayerActivitySuccess
  | ReportPlayerActivityUnSuccess
  | ReportLimitActivity
  | ReportLimitActivitySuccess
  | ReportLimitActivityUnSuccess
  | ReportFeeActivity
  | ReportFeeActivitySuccess
  | ReportFeeActivityUnSuccess
  | ReportPaymentActivity
  | ReportPaymentActivitySuccess
  | ReportPaymentActivityUnSuccess
  | ReportScoringFee
  | ReportScoringFeeSuccess
  | ReportScoringFeeUnSuccess
  | ReportProgramFee
  | ReportProgramFeeSuccess
  | ReportProgramFeeUnSuccess
  | ReportCWAEnableDisable
  | ReportCWAEnableDisableSuccess
  | ReportCWAEnableDisableUnSuccess
  | ReportLimitAdjustment
  | ReportLimitAdjustmentSuccess
  | ReportLimitAdjustmentUnSuccess
  | ReportFee
  | ReportFeeSuccess
  | ReportFeeUnSuccess
  | ReportMTSummary
  | ReportMTSummarySuccess
  | ReportMTSummaryUnSuccess
  | ReportUnderwriting
  | ReportUnderwritingSuccess
  | ReportUnderwritingUnSuccess
  | ReportCwaDetail
  | ReportCwaDetailSuccess
  | ReportCwaDetailUnSuccess
  | ReportCwaAdjustment
  | ReportCwaAdjustmentSuccess
  | ReportCwaAdjustmentUnSuccess
  | ReportPatronCwaDetails
  | ReportPatronCwaDetailsSuccess
  | ReportPatronCwaDetailsUnSuccess
  | ProgramManagementFees
  | ProgramManagementFeesSuccess
  | ProgramManagementFeesUnSuccess
  | PatronLiability
  | PatronLiabilitySuccess
  | PatronLiabilityUnSuccess
  | ReportHistory
  | ReportHistorySuccess
  | ReportHistoryUnSuccess
  | ActivitySummary
  | ActivitySummarySuccess
  | ActivitySummaryUnSuccess
  | ReportGGR
  | ReportGGRSuccess
  | ReportGGRUnSuccess
  | ReportPlayerActivityDetail
  | ReportPlayerActivityDetailSuccess
  | ReportPlayerActivityDetailUnSuccess
  | ReportFailedTransaction
  | ReportFailedTransactionSuccess
  | ReportFailedTransactionUnSuccess
