import { Injectable } from '@angular/core'
import * as crypt from 'crypto'

// https://stackoverflow.com/a/62815620/6458222

@Injectable({
  providedIn: 'root',
})
export class MtCrypto {
  private algorithm = 'aes-128-cbc'

  constructor() {}

  private _encrypt(buffer, _api_key, _iv) {
    const _key = Buffer.from(_api_key).toString('base64')
    const key = Buffer.from(_key, 'base64')
    const iv = Buffer.from(_iv, 'base64')

    const cipher = crypt.createCipheriv(this.algorithm, key, iv)
    return Buffer.concat([cipher.update(buffer, null), cipher.final()])
  }

  private _decrypt(buffer, _api_key, _iv) {
    const _key = Buffer.from(_api_key).toString('base64')
    const key = Buffer.from(_key, 'base64')
    const iv = Buffer.from(_iv, 'base64')

    const decipher = crypt.createDecipheriv(this.algorithm, key, iv)
    return Buffer.concat([decipher.update(buffer), decipher.final()])
  }

  encrypt(buffer, key, iv) {
    let encryptedData = this._encrypt(buffer, key, iv)
    return encryptedData.toString('base64')
  }

  decrypt(encrypted, key, iv) {
    let encryptedData = Buffer.from(encrypted, 'base64')
    return this._decrypt(encryptedData, key, iv).toString('utf8')
  }

  encryptJson(data, key, iv) {
    return this.encrypt(JSON.stringify(data), key, iv)
  }

  decryptJson(data, key, iv) {
    return JSON.parse(this.decrypt(data, key, iv))
  }
}
