import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable()
export class ConfigService {
  private appConfig

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/config/env.json')
      .toPromise()
      .then(data => {
        this.appConfig = data
      })
      .catch((response: any) => {
        console.log(`Failed to load the config file`)
      })
  }

  getConfig() {
    return this.appConfig ? this.appConfig : ''
  }
}
