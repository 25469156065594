export const getBreadCrumbData: any[] = [
  {
    title: 'Players',
    key: 'players',
    url: '/player-management/players',
    children: [
      {
        title: 'Player Details',
        key: 'player-details',
        url: '/player-management/player-details/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Employees',
    key: 'employees',
    url: '/employees',
    children: [
      {
        title: 'Activity History',
        key: 'report-table',
        url: '/view-user/activity-history/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Reports',
    key: 'reports',
    url: '/reports',
    children: [
      {
        title: 'Report Details',
        key: 'report-table',
        url: '/reports/report-table/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Properties Management',
    key: 'propertiesManagement',
    url: '/properties-management',
  },
  {
    title: 'Notifications',
    key: 'notifications',
    url: '/notifications',
  },
  {
    title: 'Settings',
    key: 'settings',
    url: '/settings',
  },
  {
    title: 'Settlement',
    key: 'settlement',
    url: '/settlement',
    children: [
      {
        title: 'Registration',
        key: 'registration',
        url: '/settlement/registration',
      },
    ],
  },
]
