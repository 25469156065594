import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { LocalService } from './local.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private localService: LocalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(this.localService.getJsonValue('userInfo'))

    if (currentUser && currentUser.SessionId) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      })
    }

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // console.log(err.error.Message !== 'Invalid session.')
            if (err.error.Message !== 'Invalid session.') {
              return
            }
            this.router.navigate(['auth/login'])
          }
        },
      ),
    )
  }
}
